import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import {
  FaUser,
  FaUserTie,
  FaTruck,
  FaBox,
  FaTags,
  FaSeedling,
  FaFileInvoice,
  FaYoutube,
} from "react-icons/fa";
import { AiOutlineIdcard } from "react-icons/ai";
import { BiSpreadsheet } from "react-icons/bi";
import { MdOutlineStairs } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const menuItems = [
  { icon: FaYoutube, text: "Instructivo", route: "https://www.youtube.com/" },
  { icon: FaFileInvoice, text: "Presupuestos", route: "/home/budgets" },
  { icon: BiSpreadsheet, text: "Precios", route: "/home/prices" },
  { icon: AiOutlineIdcard, text: "Perfiles", route: "/home/profiles" },
  { icon: FaUser, text: "Usuarios", route: "/home/users" },
  { icon: FaUserTie, text: "Clientes", route: "/home/clients" },
  { icon: FaTruck, text: "Proveedores", route: "/home/providers" },
  { icon: FaBox, text: "Productos", route: "/home/products" },
  { icon: FaTags, text: "Categorías", route: "/home/categories" },
  { icon: FaSeedling, text: "Cultivos", route: "/home/crop_types" },
  { icon: MdOutlineStairs, text: "Etapas", route: "/home/stage_types" },
];

const Home = () => {
  const navigate = useNavigate();

  const handleButtonClick = (route) => {
    if (route.startsWith('http')) {
      window.open(route, '_blank');
    } else {
      navigate(route);
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center py-4">
      <Row className="justify-content-center g-4">
        {menuItems.map((item, index) => (
          <Col
            key={index}
            xs={6}
            md={4}
            lg={3}
            className="d-flex justify-content-center"
          >
            <Button
              variant="primary"
              onClick={() => handleButtonClick(item.route)}
              className="d-flex flex-column align-items-center justify-content-center p-3"
              style={{
                width: "100%",
                aspectRatio: "1",
                maxWidth: "140px",
              }}
            >
              <item.icon
                className="mb-2"
                style={{ fontSize: "clamp(24px, 4vw, 32px)" }}
              />
              <span
                style={{
                  fontSize: "clamp(14px, 2vw, 20px)",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {item.text}
              </span>
            </Button>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Home;

import { createSlice } from "@reduxjs/toolkit";
import { withAuthentication } from "../middlewares";
import {
  getStageTypesWithFilters,
  getStageTypeById,
  createStageType,
  editStageTypeById,
  deleteStageTypeById,
  processStagesCsvFile
} from "../actions/stageTypesThunks";

const initialState = {
  data: [], // Lista de tipos de etapas
  stageType: null, // Detalle de un tipo de etapa
  loading: false, // Indicador de carga
  error: null, // Error
  successMessage: null, // Mensaje de éxito
  errorMessage: null, // Mensaje de error
};

const stageTypesSlice = createSlice({
  name: "stageTypes",
  initialState,
  reducers: {
    // Obtener todos los tipos de etapas
    fetchStageTypesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchStageTypesSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchStageTypesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // Obtener un tipo de etapa por UUID
    fetchStageTypeStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchStageTypeSuccess(state, action) {
      state.loading = false;
      state.stageType = action.payload;
    },
    fetchStageTypeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearStageType(state) {
      state.stageType = null;
    },
    // Crear un nuevo tipo de etapa
    createStageTypeStart(state) {
      state.loading = true;
      state.error = null;
    },
    createStageTypeSuccess(state, action) {
      state.loading = false;
      state.data.push(action.payload);
      state.successMessage = "Tipo de etapa creado exitosamente";
    },
    createStageTypeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // Editar un tipo de etapa por UUID
    editStageTypeStart(state) {
      state.loading = true;
      state.error = null;
    },
    editStageTypeSuccess(state, action) {
      state.loading = false;
      const updatedStageType = action.payload;
      const index = state.data.findIndex(
        (stage) => stage.uuid === updatedStageType.uuid
      );
      if (index !== -1) {
        state.data[index] = updatedStageType;
      }
      state.successMessage = "Tipo de etapa actualizado exitosamente";
    },
    editStageTypeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    editCloseMessage(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    // Eliminar un tipo de etapa por UUID
    deleteStageTypeStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteStageTypeSuccess(state, action) {
      state.loading = false;
      const uuid = action.payload;
      state.data = state.data.filter((stage) => stage.uuid !== uuid);
      state.successMessage = "Tipo de etapa eliminado exitosamente";
    },
    deleteStageTypeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    processCsvFileStart(state) {
      state.loading = true;
      state.error = null;
    },
    processCsvFileSuccess(state, action) {
      state.loading = false;
      state.data = [...state.data, ...action.payload];
      state.successMessage = "Archivo CSV procesado exitosamente";
    },
    processCsvFileFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Thunks asociados con `withAuthentication`
export const fetchStageTypes = withAuthentication(
  (authToken, filters) => async (dispatch) => {
    dispatch(fetchStageTypesStart());
    try {
      const response = await getStageTypesWithFilters(authToken, filters);
      dispatch(fetchStageTypesSuccess(response.stage_types || []));
    } catch (error) {
      dispatch(fetchStageTypesFailure(error.message));
    }
  }
);

export const fetchStageType = withAuthentication(
  (authToken, stageTypeUuid) => async (dispatch) => {
    dispatch(fetchStageTypeStart());
    try {
      const response = await getStageTypeById(authToken, stageTypeUuid);
      dispatch(fetchStageTypeSuccess(response));
    } catch (error) {
      dispatch(fetchStageTypeFailure(error.message));
    }
  }
);

export const createNewStageType = withAuthentication(
  (authToken, newStageType) => async (dispatch) => {
    dispatch(createStageTypeStart());
    try {
      const response = await createStageType(authToken, newStageType);
      dispatch(createStageTypeSuccess(response));
    } catch (error) {
      dispatch(createStageTypeFailure(error.message));
    }
  }
);

export const editStageType = withAuthentication(
  (authToken, stageTypeUuid, editedStageType) => async (dispatch) => {
    dispatch(editStageTypeStart());
    try {
      const response = await editStageTypeById(
        authToken,
        stageTypeUuid,
        editedStageType
      );
      dispatch(editStageTypeSuccess(response));
    } catch (error) {
      dispatch(editStageTypeFailure(error.message));
    }
  }
);

export const deleteStageType = withAuthentication(
  (authToken, stageTypeUuid) => async (dispatch) => {
    dispatch(deleteStageTypeStart());
    try {
      await deleteStageTypeById(authToken, stageTypeUuid);
      dispatch(deleteStageTypeSuccess(stageTypeUuid));
    } catch (error) {
      dispatch(deleteStageTypeFailure(error.message));
    }
  }
);

export const processCsvFile = withAuthentication(
  (authToken, formData) => async (dispatch) => {
    dispatch(processCsvFileStart());
    try {
      const response = await processStagesCsvFile(authToken, formData);
      dispatch(processCsvFileSuccess(response));
      return response;
    } catch (error) {
      dispatch(processCsvFileFailure(error.message));
    }
  }
);

// Acciones exportadas
export const {
  fetchStageTypesStart,
  fetchStageTypesSuccess,
  fetchStageTypesFailure,
  fetchStageTypeStart,
  fetchStageTypeSuccess,
  fetchStageTypeFailure,
  clearStageType,
  createStageTypeStart,
  createStageTypeSuccess,
  createStageTypeFailure,
  editStageTypeStart,
  editStageTypeSuccess,
  editStageTypeFailure,
  editCloseMessage,
  deleteStageTypeStart,
  deleteStageTypeSuccess,
  deleteStageTypeFailure,
  clearMessages,
  processCsvFileStart,
  processCsvFileSuccess,
  processCsvFileFailure,
} = stageTypesSlice.actions;

// Selectores
export const selectStageTypes = (state) => state.stageTypes.data;
export const selectStageTypeById = (state) => state.stageTypes.stageType;
export const selectStageTypesLoading = (state) => state.stageTypes.loading;
export const selectStageTypesError = (state) => state.stageTypes.error;
export const selectStageTypesSuccessMessage = (state) =>
  state.stageTypes.successMessage;
export const selectStageTypesErrorMessage = (state) =>
  state.stageTypes.errorMessage;

// Reducer exportado
export default stageTypesSlice.reducer;

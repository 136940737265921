import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClients,
  selectClients,
  selectClientsError,
  selectClientsLoading,
  selectClientById,
  clearClient,
  fetchClientById,
} from "../../redux/reducers/clientsReducer";
import { selectLoggedUserProfile } from "../../redux/reducers/authReducer";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import SearchBar from "../../components/Searchbar";
import NewClientModal from "./NewClientModal";
import EditClientModal from "./EditClientModal";
import DeleteClientModal from "./DeleteClientModal";
import "../styles/Clients.css";
import { FaPlus, FaUserTie } from "react-icons/fa";

const Clients = () => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const loading = useSelector(selectClientsLoading);
  const clients = useSelector(selectClients);
  const error = useSelector(selectClientsError);
  const client = useSelector(selectClientById);
  const profilesAuth = useSelector(selectLoggedUserProfile);

  useEffect(() => {
    dispatch(fetchClients(""));
  }, [dispatch]);

  const hasManageClientAccess = profilesAuth?.some((profile) =>
    profile.accesses?.some((access) => access.name === "manage_clients")
  );

  //Popups
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewClientModal, setShowNewClientModal] = useState(false);

  const handleEditOpen = (client_uuid) => {
    dispatch(fetchClientById(client_uuid));
    setShowEditModal(true);
  };

  const handleEditClose = () => {
    dispatch(clearClient());
    setShowEditModal(false);
  };

  const handleDeleteOpen = (client_uuid) => {
    dispatch(fetchClientById(client_uuid));
    setShowDeleteModal(true);
  };

  const handleDeleteClose = (client_uuid) => {
    dispatch(clearClient());
    setShowDeleteModal(false);
  };

  const handleNewClientOpen = () => {
    setShowNewClientModal(true);
  };

  const handleNewClientClose = () => {
    setShowNewClientModal(false);
  };

  //Searchbar
  const handleSearch = (text) => {
    dispatch(fetchClients(text));
  };

  return (
    <Container className="pt-4 pb-4">
      <Row>
        <Col>
          <Container className="pb-4 align-items-center">
            <Row className="align-items-center">
              <Col sm="3" md="3" lg="3">
                <h1 style={{ marginBottom: 0 }}>Clientes</h1>
              </Col>
              <Col sm="7" md="7" lg="7">
                <SearchBar
                  onSearch={handleSearch}
                  parameterName={"nombre, contacto o CUIT"}
                />
              </Col>
              {hasManageClientAccess && (
                <Col sm="2" md="2" lg="2">
                  <Button
                    variant="primary"
                    className="custom-button d-flex align-items-center justify-content-center"
                    onClick={handleNewClientOpen}
                  >
                    <FaPlus />
                    <FaUserTie size={30} style={{ marginLeft: "10px" }} />
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : clients.length === 0 ? (
            <p>No se encontraron clientes.</p>
          ) : (
            <Table striped bordered hover variant="dark" className="custom-table">
              <thead>
                <tr>
                  <th className="custom-table-header">Nombre</th>
                  <th className="custom-table-header">Contacto</th>
                  <th className="custom-table-header">Cuit</th>
                  <th className="custom-table-header">Teléfono</th>
                  {hasManageClientAccess && (
                    <th style={{ textAlign: "center", width: "120px" }} className="custom-table-header">
                      Acciones
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {clients.map((client) => (
                  <tr key={client.uuid}>
                    <td>{client.business_name}</td>
                    <td>{client.contact_name}</td>
                    <td>{client.cuit}</td>
                    <td>{client.phone}</td>
                    {hasManageClientAccess && (
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="warning"
                          size="sm"
                          className="me-2"
                          onClick={() => handleEditOpen(client.uuid)}
                        >
                          <PencilSquare />
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteOpen(client.uuid)}
                        >
                          <Trash />
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {/*POPUPS*/}
          <NewClientModal
            showNewClientModal={showNewClientModal}
            handleClose={handleNewClientClose}
            handleSearch={handleSearch}
          />
          <EditClientModal
            showEditModal={showEditModal}
            handleClose={handleEditClose}
            client={client}
            handleSearch={handleSearch}
          />
          <DeleteClientModal
            showDeleteModal={showDeleteModal}
            handleClose={handleDeleteClose}
            client={client}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Clients;

export const createBudget = async (token, budgetData) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/budgets/create`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(budgetData),
      });

      if (!response.ok) {
          throw new Error(`Failed operation: ${response.statusText}`);
      }

      const data = await response.json();
      return data; // Devuelve los datos del backend
  } catch (error) {
      console.error('Error:', error);
      throw error; // Lanza el error para que se maneje en el reducer
  }
};

export const getBudgetsWithFilters = async (token, filters) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/budgets/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({filters}),
      });

      if (!response.ok) {
          throw new Error(`Failed operation: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error:', error);
      throw error;
  }
};

export const getBudgetById = async (token, budget_uuid) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/budgets/${budget_uuid}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
      });

      if (!response.ok) {
          throw new Error(`Failed operation: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error:', error);
      throw error;
  }
};

export const updateBudgetById = async (token, budget_uuid, budgetData) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/budgets/${budget_uuid}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(budgetData),
      });

      if (!response.ok) {
          throw new Error(`Failed operation: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error:', error);
      throw error;
  }
};

export const deleteBudgetById = async (token, budget_uuid) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/budgets/${budget_uuid}`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
      });

      if (!response.ok) {
          throw new Error(`Failed operation: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error:', error);
      throw error;
  }
};

export const printBudgetById = async (token, budget_uuid) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/budgets/${budget_uuid}/pdf`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
 
    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }
 
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    // El nombre del archivo vendrá del Content-Disposition header
    const contentDisposition = response.headers.get('Content-Disposition');
    const fileName = contentDisposition.split('filename=')[1].replace(/"/g, '');
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
    
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
 };
 
 export const exportBudgetToExcel = async (token, budget_uuid) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/budgets/${budget_uuid}/xlsx`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
 
    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }
 
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers.get('Content-Disposition');
    const fileName = contentDisposition.split('filename=')[1].replace(/"/g, '');
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
 
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
 };
import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";

const CSVSelectionModal = ({
  show,
  onHide,
  csvResults,
  onConfirmSelection,
  stageTypes, // Se le pasa la lista de stageTypes para mapear uuid → nombre
}) => {
  // La estructura de 'selections' será:
  // { [stageUuid]: { [productIndex]: { selected: boolean, quantity: number, selectedProviderIndex: number } } }
  const [selections, setSelections] = useState({});

  // Cada vez que csvResults cambie, inicializamos las selecciones de forma predeterminada
  useEffect(() => {
    if (csvResults && csvResults.length > 0) {
      const newSelections = {};
      csvResults.forEach((stageGroup) => {
        const stageUuid = stageGroup.uuid_stage_type;
        newSelections[stageUuid] = {};
        stageGroup.product_providers.forEach((prod, idx) => {
          newSelections[stageUuid][idx] = {
            selected: true,
            quantity: prod.quantity,
            selectedProviderIndex: 0,
          };
        });
      });
      setSelections(newSelections);
    }
  }, [csvResults]);

  const handleToggle = (stageUuid, productIndex, defaultQuantity) => {
    setSelections((prev) => {
      const stageSel = prev[stageUuid] || {};
      const current = stageSel[productIndex] || {
        selected: false,
        quantity: defaultQuantity,
        selectedProviderIndex: 0,
      };
      return {
        ...prev,
        [stageUuid]: {
          ...stageSel,
          [productIndex]: { ...current, selected: !current.selected },
        },
      };
    });
  };

  const handleProviderChange = (stageUuid, productIndex, newProviderIndex) => {
    setSelections((prev) => {
      const stageSel = prev[stageUuid] || {};
      const current = stageSel[productIndex] || {
        selected: false,
        quantity: 0,
        selectedProviderIndex: newProviderIndex,
      };
      return {
        ...prev,
        [stageUuid]: {
          ...stageSel,
          [productIndex]: { ...current, selectedProviderIndex: newProviderIndex },
        },
      };
    });
  };

  // Función para obtener el nombre del stage a partir de su uuid
  const getStageName = (stageUuid) => {
    const stage = stageTypes && stageTypes.find((st) => st.uuid === stageUuid);
    return stage ? stage.name : stageUuid;
  };

  const handleConfirm = () => {
    const selectionsArray = [];
    csvResults.forEach((stageGroup) => {
      const { uuid_stage_type, product_providers } = stageGroup;
      product_providers.forEach((prod, index) => {
        const sel = (selections[uuid_stage_type] || {})[index];
        if (sel && sel.selected) {
          // Obtenemos el proveedor seleccionado (si hay proveedores)
          const provider =
            prod.providers && prod.providers.length > 0
              ? prod.providers[sel.selectedProviderIndex]
              : null;
          selectionsArray.push({
            stageUuid: uuid_stage_type,
            product: prod,
            quantity: Number(sel.quantity) || Number(prod.quantity),
            selectedProvider: provider,
          });
        }
      });
    });
    onConfirmSelection(selectionsArray);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar productos desde CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {csvResults && csvResults.length > 0 ? (
          csvResults.map((stageGroup) => (
            <div key={stageGroup.uuid_stage_type}>
              <h5>{getStageName(stageGroup.uuid_stage_type)}</h5>
              <Table striped bordered hover variant="dark" className="custom-table">
                <thead className="custom-table-header">
                  <tr>
                    <th className="text-center align-center">Agregar</th>
                    <th style={{ width: "500px" }}>Producto</th>
                    <th style={{ width: "100px" }}>Cantidad</th>
                    <th>Proveedor</th>
                  </tr>
                </thead>
                <tbody>
                  {stageGroup.product_providers.map((prod, idx) => (
                    <tr key={idx}>
                      <td className="text-center align-middle">
                        <Form.Check
                          type="checkbox"
                          checked={
                            selections[stageGroup.uuid_stage_type] &&
                            selections[stageGroup.uuid_stage_type][idx] &&
                            selections[stageGroup.uuid_stage_type][idx].selected
                          }
                          onChange={() =>
                            handleToggle(stageGroup.uuid_stage_type, idx, prod.quantity)
                          }
                        />
                      </td>
                      <td className="align-middle">{prod.description}</td>
                      <td className="align-middle">
                        {(Math.ceil(prod.quantity * 100) / 100).toFixed(2)}
                      </td>
                      <td>
                        {prod.providers && prod.providers.length > 0 ? (
                          <Form.Control
                            as="select"
                            onChange={(e) =>
                              handleProviderChange(
                                stageGroup.uuid_stage_type,
                                idx,
                                Number(e.target.value)
                              )
                            }
                          >
                            {prod.providers.map((prov, pIdx) => (
                              <option key={prov.uuid_product_provider} value={pIdx}>
                                {prov.provider_name} - ${prov.price.toFixed(2)}
                              </option>
                            ))}
                          </Form.Control>
                        ) : (
                          "Sin proveedores"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />
            </div>
          ))
        ) : (
          <p>No hay resultados del CSV.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Agregar seleccionados
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CSVSelectionModal;

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import SearchBar from "../../components/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  fetchUserById,
  selectUsers,
  selectUsersError,
  selectUsersLoading,
  clearUser,
  selectUserById,
} from "../../redux/reducers/usersReducer";
import { selectLoggedUserProfile } from "../../redux/reducers/authReducer";
import NewUserModal from "./NewUserModal";
import DeleteUserModal from "./DeleteUserModal";
import EditUserModal from "./EditUserModal";
import { PersonCircle, PencilSquare, Trash } from "react-bootstrap-icons";
import "../styles/User.css";
import { FaPlus, FaUserPlus } from "react-icons/fa";

const Users = () => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const loading = useSelector(selectUsersLoading);
  const users = useSelector(selectUsers);
  const error = useSelector(selectUsersError);
  const user = useSelector(selectUserById);
  const profilesAuth = useSelector(selectLoggedUserProfile);

  useEffect(() => {
    dispatch(fetchUsers(""));
  }, [dispatch]);

  const handleSearch = (text) => {
    dispatch(fetchUsers(text));
  };

  //Popups
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);

  const handleNewUserOpen = () => {
    setShowNewUserModal(true);
  };

  const handleNewUserClose = () => {
    setShowNewUserModal(false);
    dispatch(fetchUsers(""));
  };

  const handleDeleteClose = () => {
    dispatch(clearUser());
    setShowDeleteModal(false);
    dispatch(fetchUsers(""));
  };

  const handleEditClose = () => {
    dispatch(clearUser());
    setShowEditModal(false);
    dispatch(fetchUsers(""));
  };

  const handleDeleteOpen = (uuiduser) => {
    dispatch(fetchUserById(uuiduser));
    setShowDeleteModal(true);
  };

  const handleEditOpen = (useruuid) => {
    dispatch(fetchUserById(useruuid));
    setShowEditModal(true);
  };

  const hasManageUserAccess = profilesAuth?.some((profile) =>
    profile.accesses?.some((access) => access.name === "manage_users")
  );

  return (
    <Container className="pt-4 pb-4">
      <Container className="pb-4 align-items-center">
        <Row className="align-items-center">
          <Col sm="3" md="3" lg="3">
            <h1 style={{ marginBottom: 0 }}>Usuarios</h1>
          </Col>
          <Col sm="7" md="7" lg="7">
            <SearchBar
              onSearch={handleSearch}
              parameterName={"nombre o apellido"}
            />
          </Col>
          <Col sm="2" md="2" lg="2">
            <Button
              variant="primary"
              className="custom-button d-flex align-items-center justify-content-center"
              onClick={handleNewUserOpen}
            >
              <FaUserPlus size={30} style={{ marginLeft: "10px" }} />
            </Button>
          </Col>
        </Row>
      </Container>
      {loading ? (
        <p>Cargando...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : users.length === 0 ? (
        <p>No se encontraron usuarios</p>
      ) : (
        <Table striped bordered hover variant="dark" className="custom-table">
          <thead>
            <tr>
              <th className="custom-table-header">Nombre</th>
              <th className="custom-table-header">Email</th>
              {hasManageUserAccess && (
                <th style={{ textAlign: "center", width: "120px" }} className="custom-table-header">
                  Acciones
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.uuid}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                {hasManageUserAccess && (
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="warning"
                      size="sm"
                      className="me-2"
                      onClick={() => handleEditOpen(user.uuid)}
                    >
                      <PencilSquare />
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeleteOpen(user.uuid)}
                    >
                      <Trash />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/*POPUPS*/}
      <NewUserModal
        handleClose={handleNewUserClose}
        showNewUserModal={showNewUserModal}
      />
      <EditUserModal
        showEditModal={showEditModal}
        handleClose={handleEditClose}
        user={user}
      />
      <DeleteUserModal
        showDeleteModal={showDeleteModal}
        handleClose={handleDeleteClose}
        user={user}
      />
    </Container>
  );
};

export default Users;

import { createSlice } from '@reduxjs/toolkit';
import { withAuthentication } from '../middlewares';
import {
    createBudget,
    getBudgetsWithFilters,
    getBudgetById,
    updateBudgetById,
    deleteBudgetById,
    printBudgetById,
    exportBudgetToExcel
} from '../actions/budgetsThunks';

const initialState = {
    budgets: [], // Lista de presupuestos
    budget: null, // Presupuesto actual
    loading: false, // Estado de carga
    error: null, // Error en la operación
    successMessage: null, // Mensaje de éxito
    errorMessage: null, // Mensaje de error
};

const budgetsSlice = createSlice({
    name: 'budgets',
    initialState,
    reducers: {
        // Crear presupuesto
        createBudgetStart(state) {
            state.loading = true;
            state.error = null;
            state.successMessage = null;
            state.errorMessage = null;
        },
        createBudgetSuccess(state, action) {
            state.loading = false;
            state.budget = action.payload;
            state.successMessage = 'Presupuesto creado exitosamente';
            state.errorMessage = null;
        },
        createBudgetFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al crear presupuesto';
            state.successMessage = null;
        },

        // Obtener presupuestos con filtros
        fetchBudgetsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchBudgetsSuccess(state, action) {
            state.loading = false;
            state.budgets = action.payload;
        },
        fetchBudgetsFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },

        // Obtener un presupuesto por ID
        fetchBudgetStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchBudgetSuccess(state, action) {
            state.loading = false;
            state.budget = action.payload;
        },
        fetchBudgetFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearBudget(state) {
            state.budget = null;
        },

        // Actualizar presupuesto
        editBudgetStart(state) {
            state.loading = true;
            state.error = null;
        },
        editBudgetSuccess(state, action) {
            state.loading = false;
            state.budget = action.payload;
            state.successMessage = 'Presupuesto actualizado exitosamente';
            state.errorMessage = null;
        },
        editBudgetFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.errorMessage = 'Error al actualizar presupuesto';
            state.successMessage = null;
        },
        editCloseMessage(state) {
          state.successMessage = null;
          state.errorMessage = null;
        },
        // Eliminar presupuesto
        deleteBudgetStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteBudgetSuccess(state, action) {
            state.loading = false;
            state.error = null;

            const budgetUuid = action.payload;
            const deletedBudgetIndex = state.budgets.findIndex(budget => budget.uuid === budgetUuid);

            if (deletedBudgetIndex !== -1) {
                state.budgets.splice(deletedBudgetIndex, 1);
                if (state.budget && state.budget.uuid === budgetUuid) {
                    state.budget = null;
                }
            }
        },
        deleteBudgetFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },

        // Cerrar mensajes
        closeMessages(state) {
            state.successMessage = null;
            state.errorMessage = null;
        },
    },
});

// Thunks para manejar las operaciones
export const fetchBudgets = withAuthentication((authToken, ...args) => async (dispatch, getState) => {
    dispatch(fetchBudgetsStart());
    try {
        const response = await getBudgetsWithFilters(authToken, ...args);
        dispatch(fetchBudgetsSuccess(response.budgets || []));
    } catch (error) {
        dispatch(fetchBudgetsFailure(error.message));
    }
});

export const fetchBudgetById = withAuthentication((authToken, budgetUuid) => async (dispatch, getState) => {
    dispatch(fetchBudgetStart());
    try {
        const budget = await getBudgetById(authToken, budgetUuid);
        dispatch(fetchBudgetSuccess(budget));
    } catch (error) {
        dispatch(fetchBudgetFailure(error.message));
    }
});

export const createNewBudget = withAuthentication((authToken, budgetData) => async (dispatch, getState) => {
    dispatch(createBudgetStart());
    try {
        const response = await createBudget(authToken, budgetData);
        dispatch(createBudgetSuccess(response.budget));
    } catch (error) {
        dispatch(createBudgetFailure(error.message));
    }
});

export const editBudget = withAuthentication((authToken, budgetUuid, budgetData) => async (dispatch, getState) => {
    dispatch(editBudgetStart());
    try {
        const response = await updateBudgetById(authToken, budgetUuid, budgetData);
        dispatch(editBudgetSuccess(response.budget));
    } catch (error) {
        dispatch(editBudgetFailure(error.message));
    }
});

export const deleteBudget = withAuthentication((authToken, budgetUuid) => async (dispatch, getState) => {
    dispatch(deleteBudgetStart());
    try {
        const response = await deleteBudgetById(authToken, budgetUuid);
        dispatch(deleteBudgetSuccess(response.budgetUuid));
    } catch (error) {
        dispatch(deleteBudgetFailure(error.message));
    }
});

export const printPDFBudget = withAuthentication((authToken, budgetUuid) => async (dispatch) => {
  try {
      await printBudgetById(authToken, budgetUuid);
  } catch (error) {
      console.error('Error generating PDF:', error);
  }
});

export const printXLSXBudget = withAuthentication((authToken, budgetUuid) => async (dispatch) => {
  try {
      await exportBudgetToExcel(authToken, budgetUuid);
  } catch (error) {
      console.error('Error generating PDF:', error);
  }
});

// Selectores
export const selectBudgets = state => state.budgets.budgets;
export const selectBudgetById = state => state.budgets.budget;
export const selectBudgetsLoading = state => state.budgets.loading;
export const selectBudgetsError = state => state.budgets.error;
export const selectBudgetsSuccessMessage = state => state.budgets.successMessage;
export const selectBudgetsErrorMessage = state => state.budgets.errorMessage;

export const {
    createBudgetStart,
    createBudgetSuccess,
    createBudgetFailure,
    fetchBudgetsStart,
    fetchBudgetsSuccess,
    fetchBudgetsFailure,
    fetchBudgetStart,
    fetchBudgetSuccess,
    fetchBudgetFailure,
    clearBudget,
    editBudgetStart,
    editBudgetSuccess,
    editBudgetFailure,
    editCloseMessage,
    deleteBudgetStart,
    deleteBudgetSuccess,
    deleteBudgetFailure,
    closeMessages,
} = budgetsSlice.actions;

export default budgetsSlice.reducer;

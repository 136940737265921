import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  selectCategories,
  selectCategoriesError,
  selectCategoriesLoading,
  selectCategoryById,
  clearCategory,
  fetchCategoryById,
} from "../../redux/reducers/categoriesReducer";
import { selectLoggedUserProfile } from "../../redux/reducers/authReducer";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import SearchBar from "../../components/Searchbar";
import "../styles/Categories.css";
import NewCategoryModal from "./NewCategoryModal";
import EditCategoryModal from "./EditCategoryModal";
import DeleteCategoryModal from "./DeleteCategoryModal";
import { FaPlus, FaTags } from "react-icons/fa";

const Categories = () => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const loading = useSelector(selectCategoriesLoading);
  const categories = useSelector(selectCategories);
  const error = useSelector(selectCategoriesError);
  const category = useSelector(selectCategoryById);
  const profilesAuth = useSelector(selectLoggedUserProfile);

  useEffect(() => {
    dispatch(fetchCategories(null));
  }, [dispatch]);

  const hasManageCategoriesAccess = profilesAuth?.some((profile) =>
    profile.accesses?.some((access) => access.name === "manage_categories")
  );

  //Popups
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewEntityModal, setShowNewEntityModal] = useState(false);

  const handleEditOpen = (category_uuid) => {
    dispatch(fetchCategoryById(category_uuid));
    setShowEditModal(true);
  };

  const handleEditClose = () => {
    dispatch(clearCategory());
    setShowEditModal(false);
  };

  const handleDeleteOpen = (category_uuid) => {
    dispatch(fetchCategoryById(category_uuid));
    setShowDeleteModal(true);
  };

  const handleDeleteClose = (category_uuid) => {
    dispatch(clearCategory());
    setShowDeleteModal(false);
  };

  const handleNewEntityOpen = () => {
    setShowNewEntityModal(true);
  };

  const handleNewEntityClose = () => {
    setShowNewEntityModal(false);
  };

  //Searchbar
  const handleSearch = (text) => {
    dispatch(fetchCategories(text));
  };

  return (
    <Container className="pt-4 pb-4">
      <Row>
        <Col>
          <Container className="pb-4 align-items-center">
            <Row className="align-items-center">
              <Col sm="3" md="3" lg="3">
                <h1 style={{ marginBottom: 0 }}>Categorías</h1>
              </Col>
              <Col sm="7" md="7" lg="7">
                <SearchBar onSearch={handleSearch} parameterName={"nombre"} />
              </Col>
              {hasManageCategoriesAccess && (
                <Col sm="2" md="2" lg="2">
                  <Button
                    variant="primary"
                    className="custom-button d-flex align-items-center justify-content-center"
                    onClick={handleNewEntityOpen}
                  >
                    <FaPlus />
                    <FaTags size={30} style={{ marginLeft: "10px" }} />
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : categories.length === 0 ? (
            <p>No se encontraron categorias.</p>
          ) : (
            <>
              <Table
                striped
                bordered
                hover
                variant="dark"
                className="custom-table"
              >
                <thead>
                  <tr>
                    <th className="custom-table-header">Nombre</th>
                    <th
                      className="custom-table-header"
                      style={{ textAlign: "center", width: "150px" }}
                    >
                      Unidad mínima
                    </th>
                    <th
                      className="custom-table-header"
                      style={{ textAlign: "center", width: "120px" }}
                    >
                      Error
                    </th>
                    {hasManageCategoriesAccess && (
                      <th
                        style={{ textAlign: "center", width: "120px" }}
                        className="custom-table-header"
                      >
                        Acciones
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category) => (
                    <tr key={category.uuid}>
                      <td>{category.name}</td>
                      <td style={{ textAlign: "center" }}>{category.minimum_unit}</td>
                      <td style={{ textAlign: "center" }}>
                        {category.error_rate ? category.error_rate + "%" : "-"}
                      </td>
                      {hasManageCategoriesAccess && (
                        <td style={{ textAlign: "center" }}>
                          <Button
                            variant="warning"
                            size="sm"
                            className="me-2"
                            onClick={() => handleEditOpen(category.uuid)}
                          >
                            <PencilSquare />
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteOpen(category.uuid)}
                          >
                            <Trash />
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}

          {/*POPUPS*/}
          <NewCategoryModal
            showNewEntityModal={showNewEntityModal}
            handleClose={handleNewEntityClose}
            handleSearch={handleSearch}
          />
          <EditCategoryModal
            showEditModal={showEditModal}
            handleClose={handleEditClose}
            category={category}
            handleSearch={handleSearch}
          />
          <DeleteCategoryModal
            showDeleteModal={showDeleteModal}
            handleClose={handleDeleteClose}
            category={category}
            handleSearch={handleSearch}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Categories;
